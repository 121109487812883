import React from 'react';

import './SocialAppHeader.scss';

export default function SocialAppHeader({ data }) {

  const { images, title, subtitle } = data;

  return (
    <div className='socialapp-header-container' style={{ backgroundImage: `url(${images.bgTop})` }}>
      <div className='socialapp-header-wrapper'>
        <div className='socialapp-header-titleBlock'>
          <h1 className='socialapp-header-title'>{title}</h1>
          <p className='socialapp-header-suptitle'>{subtitle}</p>
        </div>
      </div>
    </div>
  );
};
