import React from "react"

const PRLX = {
  YES: {
    START: 0.27,
    END: 0.04,
  },
  PARTY: {
    START: 0.68,
    END: 0.45,
  },
}

export default class ParallaxComponent extends React.Component {
  constructor(props) {
    super(props)
    this.screensParty = null
    this.screensYes = null
    this.containerYes = null
    this.startPrlx = null
    this.endPrlx = null
    this.calcParallax = this.calcParallax.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.handleBgLoaded = this.handleBgLoaded.bind(this)
    this.handleYesLoaded = this.handleYesLoaded.bind(this)
    this.handlePrtLoaded = this.handlePrtLoaded.bind(this)

    this.state = {
      bgLoad: false,
      yesLoad: false,
      prtLoad: false,
    }
  }

  componentDidMount() {
    // if (window.innerWidth > 768) {
    window.addEventListener("resize", this.calcParallax)
    window.addEventListener("scroll", this.handleScroll)
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcParallax)
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleBgLoaded() {
    this.setState({ bgLoad: true }, () => {
      this.calcParallax()
    })
  }
  handleYesLoaded() {
    this.setState({ yesLoad: true }, () => {
      this.calcParallax()
    })
  }
  handlePrtLoaded() {
    this.setState({ prtLoad: true }, () => {
      this.calcParallax()
    })
  }

  calcParallax() {
    const { bgLoad, yesLoad, prtLoad } = this.state
    if (bgLoad && yesLoad && prtLoad) {
      const containerHeight = this.containerYes.clientHeight
      const container = this.containerYes.getBoundingClientRect()
      this.startPrlx =
        window.pageYOffset +
        container.top +
        containerHeight * PRLX.YES.START -
        window.innerHeight
      this.endPrlx =
        window.pageYOffset +
        container.top +
        containerHeight * PRLX.PARTY.END +
        this.screensParty.clientHeight
      this.handleScroll()
    }
  }

  handleScroll() {
    const { bgLoad, yesLoad, prtLoad } = this.state
    if (bgLoad && yesLoad && prtLoad) {
      const containerHeight = this.containerYes.clientHeight

      if (this.startPrlx > window.pageYOffset) {
        this.setPosition(this.screensYes, containerHeight, PRLX.YES.START)
        this.setPosition(this.screensParty, containerHeight, PRLX.PARTY.START)
      } else if (this.endPrlx < window.pageYOffset) {
        this.setPosition(this.screensYes, containerHeight, PRLX.YES.END)
        this.setPosition(this.screensParty, containerHeight, PRLX.PARTY.END)
      } else {
        const kYes =
          PRLX.YES.START -
          ((window.pageYOffset - this.startPrlx) /
            (this.endPrlx - this.startPrlx)) *
            (PRLX.YES.START - PRLX.YES.END)
        const kPrt =
          PRLX.PARTY.START -
          ((window.pageYOffset - this.startPrlx) /
            (this.endPrlx - this.startPrlx)) *
            (PRLX.PARTY.START - PRLX.PARTY.END)
        this.setPosition(this.screensYes, containerHeight, kYes)
        this.setPosition(this.screensParty, containerHeight, kPrt)
      }
    }
  }

  setPosition(node, containerHeight, k) {
    const position = containerHeight * k + "px"
    node.style.top != position ? (node.style.top = position) : null
  }

  render() {
    return (
      <section className="white-bg screens">
        <div className="screens__container">
          <img
            src={this.props.images.screensBg}
            alt="screens appication"
            className="screens--bg"
            onLoad={this.handleBgLoaded}
          />
          <div className="screens__prlx--container">
            <img
              src={this.props.images.screensParty}
              alt="screens appication"
              id="screensParty"
              ref={elem => {
                this.screensParty = elem
              }}
              onLoad={this.handlePrtLoaded}
            />
          </div>
          <div
            className="screens__prlx--container"
            ref={elem => {
              this.containerYes = elem
            }}
          >
            <img
              src={this.props.images.screensYes}
              alt="screens appication"
              id="screensYes"
              ref={elem => {
                this.screensYes = elem
              }}
              onLoad={this.handleYesLoaded}
            />
          </div>
        </div>
      </section>
    )
  }
}
