import React from "react"

import ParallaxComponent from "../../../components/SingleCase/SocialApp/ParallaxComponent"
import OverviewComponent from "../../../components/SingleCase/OverviewComponent"

export default function SocialApp({ data }) {
  let i = 0
  return [
    // <section key={i++}>
    //   <div
    //     className="bgBlock bgImage Shoki"
    //     style={{ backgroundImage: `url(${data.images.bgTop})` }}
    //   >
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-md-5 col-sm-6" style={{ height: "100%" }}>
    //           <div className="titleBlock">
    //             <h1>{data.title}</h1>
    //             <span>{data.subtitle}</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>,

    <OverviewComponent {...data} key={i++} />,

    <ParallaxComponent images={data.images} key={i++} />,

    <section className="white-bg solution first" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.solution.title}</h2>
            {data.solution.textFirst.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,
    <section className="Shoki white-bg image solution" key={i++}>
      <div className="containerShoki">
        <div className="container">
          <img src={data.images.solution} alt="Social application" />
        </div>
      </div>
    </section>,
    <section className="shokiGrey-bg solution" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            {data.solution.textLast.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,
    <section className="shokiGrey-bg" key={i++}>
      <div className="image fixed middle">
        <img src={data.images.solutionLast} alt="Social application" />
      </div>
    </section>,

    <section className="white-bg solution last" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2>{data.delivered.title}</h2>
            {data.delivered.text.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
        </div>
      </div>
    </section>,
  ]
}
