import {
  appIcon,
  Party,
  Slide1,
  Slide3,
  Slide6,
  Slide7,
  Yes,
} from "../../images/SingleCase/SocialApp"

const dataForSocialApp = {
  type: "project",
  smart: true,
  classComponent: "Shoki",
  nextLink: "cases/crm-sap-integration/",
  prevLink: "cases/utility-management-software/",
  imageSrc: [],
  data: {
    title: "Social App",
    subtitle:
      "Application that allows you to organize events with your friends in just 3 clicks.",
    iconDescription: "Meet your friends now",
    classComponent: "Shoki",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Our client is a young lifestyle startup from Europe. The company developed an outstanding lifestyle application for event organizing and geographic matching of sweet deals. Their main product goal is connecting smart and active consumers through good deal sharing and sense of urgency.",
      },
      challenge: {
        title: "Business Challenge",
        content:
          "Our customer identified a major pain point among youngsters in organizing events with their friends: inviting friends, making sure everyone has access to the same level of information and keeping track of who is going to attend was a mess. The existing solutions included Facebook events, Google Calendar or custom apps for specific events.",
      },
      specific: [
        {
          type: "Platform",
          content: ["iOS", "Android"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "React Native",
            "npm",
            "webpack",
            "FCM",
            "Android SDK",
            "Node.js",
            "AWS",
            "MongoDB",
            "XCode",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "Project manager",
              count: 1,
            },
            {
              type: "Developers",
              count: 5,
            },
            {
              type: "Designer",
              count: 1,
            },
            {
              type: "QA Engineer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "months",
              count: 3,
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
      statistic: [
        {
          type: "Screens",
          number: 33,
          icon: "fa-mobile",
        },
        {
          type: "Components",
          number: 20,
          icon: "fa-cogs",
        },
        {
          type: "Code Lines",
          number: 12256,
          icon: "fa-code",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "The most important part of our decision was connecting event attendees with businesses that offer services for holding an event. We were convinced that we have enough leverage to pull that off as we knew who wanted to spend money on what and where. Hence, we could easily match existing demand with free capacity at local businesses.",
        "The Customer decided to develop and MVP and address both Android and iOS platforms at the same time. Considering limited budget and timeline, we used React Native for achieving faster outcome and easier code maintenance.",
        "Down the road we encountered a number of structural challenges as React Native community was still young and the libraries and tools selection was much limited. In order to overcome the challenge, we had to customize what was available and develop our own modules when applicable."
      ],
      textLast: [
        "Another large challenge was building a flexible CI solution for Android and iOS and implementing Bitbucket and JIRA integration. It took us a lot of DevOps and coffees to set up a flow that would allow us assembling builds through XCode and Android SDK, composing changelog, marking them with correct versions and submitting to the Stores. Such system allowed saving up to 70% of time previously required to swap configs and build for various environments.",
        "Special effort was dedicated to setting up and  fine-tuning Firebase Cloud Messaging and SMS services to make sure the virality loop is enclosed and allows new users to easily bring their friends to the platform.",
      ],
    },
    delivered: {
      title: "Value delivered",
      text: [
        "After a closer study, we decided to take a more risky approach and implement an application that won't be limited by Facebook or Google's APIs and would allow more flexible event management and tracking options.",
        "Going a step further, we decided that users were not only looking to satisfy their obvious needs - such as having sushi or drinking at a bar - but more complex needs such as socializing, feeling cozy or adventurous. Thus, we decided to develop a neural network to map places' attributes to the users' explicit and implicit needs.",
      ],
    },
    images: {
      bgTop: Slide1,
      iconLogo: appIcon,
      screensBg: Slide3,
      screensParty: Party,
      screensYes: Yes,
      solution: Slide6,
      solutionLast: Slide7,
    },
  },
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/service-doc",
      id: "service-doc",
      title: "Service Doc",
    },
    {
      link: "/cases/2cents",
      id: "enjoy",
      title: "2cents",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/social-app",
}

export default dataForSocialApp
